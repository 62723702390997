/**
 * Module for features and toggles
 */

export default {
  namespaced: true,

  state: () => ({
    registeredNavEntries: [],
    alternativeAdminMenu: [],
  }),

  mutations: {
    addNavEntry(state, navEntry) {
      let existing = state.registeredNavEntries
        .filter(e => e.id === navEntry.id)
        .at(0)
      if (existing) {
        existing.items = existing.items.concat(navEntry.items)
      } else {
        state.registeredNavEntries.push(navEntry)
      }
    },
    setAlternativeAdminMenu(state, menu) {
      state.alternativeAdminMenu = menu
    },
  },
  getters: {
    toggles: (state, getters, rootState, rootGetters) => {
      return rootGetters['app/context/features']().map(toggle => toggle.flag)
    },
    navEntries: (state, getters) => {
      return state.registeredNavEntries.filter(
        navEntry =>
          !navEntry.shouldBeShown || navEntry.shouldBeShown(getters.toggles)
      )
    },
    alternativeAdminMenu: (state, getters) => {
      return state.alternativeAdminMenu.filter(
        navEntry =>
          navEntry.admin &&
          (!navEntry.shouldBeShown || navEntry.shouldBeShown(getters.toggles))
      )
    },
    extEntityFlowEnabled:
      (state, getters, rootState, rootGetters) => options => {
        /**
         * Conditions:
         * flag1 - extension entityflow is registered and activated
         * flag2 - tenant experimental access is true
         * flag3 - entityflow trait is attached to the entity type
         */
        const flag1 = rootState.dm.extensions.active.includes('entityflow')
        const flag2 = rootGetters['app/context/hasExperimentalAccess']()
        const flag3 =
          options.model?.traits?.some(val => {
            return val.group === 'Flow Extension'
          }) || false

        return flag1 && flag2 && flag3
      },
  },
  actions: {},
}
