import i18n from '@i18n'

import sapi from './service'

const endpoint = 'entities'
const editEndpoint = 'editentity'
const recoverEndpoint = 'recover'
const comments = 'comments'
const transitions = 'transitions'
const chatEndpoint = 'chatentity'

export default {
  async get(id, scope, silent) {
    const { data } = await sapi.webadapter.get(`${endpoint}/${id}`, {
      params: { scope },
      omitError: silent,
    })
    prepareView(data)
    return data
  },

  async getList(ids, scope) {
    const { data } = await sapi.webadapter.post(`batch/${endpoint}/list`, ids, {
      params: { scope },
    })
    return data
  },

  async create(item) {
    const { data } = await sapi.webadapter.post(endpoint, item)
    sapi.success(i18n.t('dm.entity.alert.created'))
    item.id = data.entityId
    return data
  },

  async batchCreate(items) {
    const { data } = await sapi.webadapter.post(`batch/${endpoint}`, items)
    return data
  },

  async update(item) {
    await this.editSave(item)
    const { data } = await sapi.webadapter.put(
      `${editEndpoint}/${item.id}/commit`,
      item
    )
    return data
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.webadapter.delete(`${endpoint}/${item.id}`)
  },

  async editStart(item) {
    try {
      const { data } = await sapi.webadapter({
        method: 'post',
        url: `${editEndpoint}/${item.id}`,
        omitError: true,
      })
      prepareView(data)
      return data
    } catch (err) {
      if (sapi.isStatus(err, 412)) {
        sapi.warning(i18n.t('dm.entity.alert.stillProcessing'))
      } else {
        sapi.error(err)
      }
      return Promise.reject(err)
    }
  },

  async editSave(item, silent = false) {
    const { data } = await sapi.webadapter.put(`${endpoint}/${item.id}`, item, {
      omitError: silent,
    })
    return data
  },

  async editDiscard(item) {
    await sapi.webadapter.delete(`${endpoint}/${item.id}`)
  },

  async types(scope, creatable, includeTraits) {
    const { data } = await sapi.webadapter.get('types', {
      params: { scope, creatable, includeTraits },
    })
    return data
  },

  async display(typeId, displayId) {
    const { data } = await sapi.webadapter.get(
      `types/${typeId}/displays/${displayId}`
    )
    data.values = defaultValues(data)
    prepareView(data)
    return data
  },

  async switchType(id, newTypeId) {
    const { data } = await sapi.webadapter.get(
      `${endpoint}/${id}/typeswitch/${newTypeId}`
    )
    prepareView(data)
    return data
  },

  async batchSwitchType(ids, newTypeId) {
    const { data } = await sapi.webadapter.post(
      `batch/${endpoint}/typeswitch/${newTypeId}`,
      ids
    )
    return data
  },

  async getComments(id) {
    const { data } = await sapi.webadapter.get(`${endpoint}/${id}/${comments}`)
    return data
  },

  async addComment(id, comment) {
    return await sapi.webadapter.post(`${endpoint}/${id}/${comments}`, {
      content: comment,
    })
  },

  async deleteComment(id, commentId) {
    return await sapi.webadapter.delete(
      `${endpoint}/${id}/${comments}/${commentId}`
    )
  },

  async getTransitions(id) {
    let { data } = await sapi.webadapter.get(`${endpoint}/${id}/${transitions}`)
    return data || []
  },

  async executeTransition(id, transitionId, comment, silent = true) {
    return await sapi.webadapter.post(
      `${endpoint}/${id}/${transitions}/${transitionId}/execute`,
      comment,
      {
        omitError: silent,
      }
    )
  },

  async restore(ids) {
    const { data } = await sapi.webadapter.post(
      `batch/${recoverEndpoint}/records`,
      ids
    )
    return data || []
  },

  forward(entityId, stepId, comment) {
    return sapi.architect.post(`records/${entityId}/forward/${stepId}`, {
      comment,
    })
  },

  chatInit(entityId, fileId) {
    return sapi.webadapter.get(`${chatEndpoint}/${entityId}/file/${fileId}`)
  },

  chatClose() {
    return sapi.webadapter.delete(chatEndpoint)
  },

  async chat(question) {
    const { data } = await sapi.webadapter.post(chatEndpoint, { question })
    return data
  },
}

function defaultValues(data) {
  const values = {
    type: data.type?.id,
  }
  data.attributes.forEach(a => {
    if (a.datatype === 'boolean') {
      values[a.id] = false
    }
  })
  return values
}

function prepareView(data) {
  data?.view?.components?.forEach(comp => {
    prepareViewRec(comp, data.attributes)
  })
}

function prepareViewRec(obj, atts) {
  if (obj && typeof obj === 'object') {
    Object.keys(obj).forEach(key => {
      if (Array.isArray(obj[key])) {
        if (key === 'attributes') {
          obj[key] = obj[key]
            .map(att =>
              typeof att === 'string' ? atts.find(a => a.id === att) : att
            )
            .filter(att => typeof att !== 'undefined')
        } else {
          obj[key].forEach(e => {
            prepareViewRec(e, atts)
          })
        }
      } else {
        prepareViewRec(obj[key], atts)
      }
    })
  }
}
