const stack = []
/**
 * Module for dialogs
 */
export default {
  namespaced: true,

  state: () => ({
    show: false,
    name: '',
    options: {},
  }),

  mutations: {
    show(state, { name, options }) {
      state.show = true
      state.name = name
      state.options = typeof options !== 'undefined' ? options : {}
    },
    hide(state) {
      state.show = false
    },
  },

  actions: {
    /**
     * @param commit
     * @param payload string or object {name: name, options: {
     *  placeholders: placeholders,
     *  color: color,
     *  icon: icon
     *  }}
     * @returns {Promise<void>}
     */
    confirm({ commit }, payload) {
      if (typeof payload === 'string') {
        commit('show', { name: payload, options: {} })
      } else {
        commit('show', { name: payload.name, options: payload.options })
      }
      return new Promise(resolve => {
        stack.push({
          resolve: resolve,
        })
      })
    },
    decide({ commit }, name) {
      commit('show', { name })
      return new Promise((resolve, reject) => {
        stack.push({
          resolve: resolve,
          reject: reject,
        })
      })
    },
    resolve({ commit }) {
      commit('hide')
      const last = stack.pop()
      if (typeof last.resolve === 'function') {
        last.resolve()
      }
    },
    reject({ commit }) {
      commit('hide')
      const last = stack.pop()
      if (typeof last.reject === 'function') {
        last.reject()
      }
    },
    close({ commit }) {
      commit('hide')
      stack.pop()
    },
  },
}
