import i18n from '@i18n'

import model from './model'
import sapi from './service'

const adminEndpoint = 'actions'
const userEndpoint = 'user/actions'

export default {
  async get(id) {
    const { data } = await sapi.webadapter.get(`${adminEndpoint}/${id}`)
    return model.action(data)
  },

  async getForUser(id) {
    const { data } = await sapi.webadapter.get(`${userEndpoint}/${id}`)
    return model.action(data)
  },

  async list() {
    let { data } = await sapi.webadapter.get(adminEndpoint)
    data = data || []
    return data.map(e => model.action(e))
  },

  async listForUser() {
    let { data } = await sapi.webadapter.get(userEndpoint)
    data = data || []
    return data.map(e => model.action(e))
  },

  async create(item) {
    const { data } = await sapi.webadapter.post(adminEndpoint, item)
    sapi.success(
      i18n.t('dm.action.alert.created', {
        name: i18n.$l(item),
      })
    )
    return model.action(data)
  },

  async update(item) {
    await sapi.webadapter.put(`${adminEndpoint}/${item.id}`, item)
    sapi.success(
      i18n.t('dm.action.alert.updated', {
        name: i18n.$l(item),
      })
    )
    return model.action(item)
  },

  async save(item) {
    return item.id ? await this.update(item) : await this.create(item)
  },

  async delete(item) {
    await sapi.webadapter.delete(`${adminEndpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.action.alert.deleted', {
        name: i18n.$l(item),
      })
    )
  },
}
