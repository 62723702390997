/**
 * Import core context
 */
import router from '@router'

import store from '@state/store'

import routes from './router/routes'

/**
 * Define feature constants
 */
const VERSION = 'v0.0.1'
const FEATURE = 'cloudarchive'
const NAME = 'Cloud Archive'

/**
 * Define feature info with details of registered components
 */
const INFO = {
  name: NAME,
  feature: FEATURE,
  version: VERSION,
  routes: initRouter(),
  nav: initNav(),
}

export default INFO

/**
 * Register and initialize router context
 */
function initRouter() {
  routes.forEach(route => {
    router.addRoute('app', route)
  })

  return true
}

/**
 *  Register and initialize navigation context
 */
function initNav() {
  store.commit('app/features/setAlternativeAdminMenu', [
    {
      id: 'settings-cloudarchive',
      icon: 'mdi-cog',
      title: 'nav.item.settings.title',
      route: { name: 'settings-cloudarchive' },
      admin: true,
      shouldBeShown: featureFlags =>
        featureFlags.includes('dm-use-cloud-archive-admin-menu'),
      items: [],
    },
    {
      id: 'settings-cloudarchive-users',
      icon: 'mdi-account',
      title: 'nav.item.users.title',
      route: { name: 'settings-users' },
      admin: true,
      shouldBeShown: featureFlags =>
        featureFlags.includes('dm-use-cloud-archive-admin-menu'),
      items: [],
    },
    {
      id: 'settings-cloudarchive-mailfetcher-accounts',
      icon: 'mdi-email-edit',
      title: 'mailfetcher.nav.item.accounts.title',
      route: { name: 'settings-mailfetcher-email' },
      admin: true,
      shouldBeShown: featureFlags =>
        featureFlags.includes('dm-use-cloud-archive-admin-menu') &&
        featureFlags.includes('dm-show-admin-menu-entry-mailfetcher'),
      items: [],
    },
    {
      id: 'settings-cloudarchive-upgrade',
      icon: 'mdi-cart-outline',
      title: 'nav.group.upgrade.title',
      route: { name: 'upgrade' },
      admin: true,
      shouldBeShown: featureFlags =>
        featureFlags.includes('dm-use-cloud-archive-admin-menu') &&
        featureFlags.includes('dm-show-admin-menu-entry-upsell'),
      items: [],
    },
    {
      id: 'settings-cloudarchive-auditlog',
      icon: 'mdi-text-box-search-outline',
      title: 'nav.group.auditLog.title',
      route: { name: 'audit-log' },
      admin: true,
      tenantAdmin: true,
      shouldBeShown: featureFlags =>
        featureFlags.includes('dm-use-cloud-archive-admin-menu'),
      items: [],
    },
  ])

  return true
}
