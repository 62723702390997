import i18n from '@i18n'

import model from './model'
import sapi from './service'

const endpoint = 'tenant/serviceaccounts'

export default {
  async get(id) {
    const { data } = await sapi.landlord.get(`${endpoint}/${id}`)
    return model.serviceaccount(data)
  },

  async list() {
    let { data } = await sapi.landlord.get(endpoint)
    data = data || []
    return data.map(e => model.serviceaccount(e))
  },

  async create(item) {
    const { data } = await sapi.landlord.post(endpoint, item)
    sapi.success(
      i18n.t('dm.serviceaccount.alert.created', {
        name: item.name,
      })
    )
    return model.serviceaccount(data)
  },

  async saveRoles(item, roleIds) {
    const { data } = await sapi.landlord.put(
      `${endpoint}/${item.id}/roles`,
      roleIds
    )
    sapi.success(
      i18n.t('dm.serviceaccount.alert.updated', {
        name: item.name,
      })
    )
    return model.serviceaccount(data)
  },

  async delete(item) {
    await sapi.landlord.delete(`${endpoint}/${item.id}`)
    sapi.success(
      i18n.t('dm.serviceaccount.alert.deleted', {
        name: item.name,
      })
    )
  },
}
