import Vue from 'vue'

/**
 * Module for nav
 */
export default {
  namespaced: true,

  state: () => ({
    list: menu(),
    value: true,
    active: {},
    adminMode: false,
    recentlyAdded: false,
    toggleUserTable: false,
  }),

  mutations: {
    toggle(state, value) {
      if (typeof value === 'boolean') {
        state.value = value
      } else {
        state.value = !state.value
      }
    },
    active(state, id) {
      Object.keys(state.active).forEach(k => {
        state.active[k] = false
      })
      Vue.set(state.active, id, true)
    },
    collapse(state) {
      Object.keys(state.active).forEach(k => {
        state.active[k] = false
      })
    },
    adminMode(state, value) {
      if (typeof value === 'boolean') {
        state.adminMode = value
      } else {
        state.adminMode = !state.adminMode
      }
    },
    recentlyAdded(state, value) {
      state.recentlyAdded = value
      localStorage.setItem('app/recentlyAdded', value)
    },
    toggleUserTable(state, value) {
      if (typeof value === 'boolean') {
        state.toggleUserTable = value
      } else {
        state.toggleUserTable = !state.toggleUserTable
      }
    },
  },

  getters: {
    user: state => () => {
      return state.recentlyAdded
        ? state.list.filter(e => !e.admin)
        : state.list.filter(e => !e.admin && !e.user)
    },
    admin: (state, _getters, _rootState, rootGetters) => () => {
      if (!rootGetters['app/context/isAdmin']()) {
        return []
      }

      let menuEntries = []
      const alternativeMenu = rootGetters['app/features/alternativeAdminMenu']
      if (alternativeMenu && alternativeMenu.length > 0) {
        menuEntries = [...alternativeMenu]
      } else {
        menuEntries = [
          ...state.list,
          ...Object.values(rootGetters['app/features/navEntries'] || {}),
        ]
      }

      const activeExtensions =
        rootGetters['app/context/activeExtensions']() || []
      const activeExtensionNames = activeExtensions.map(e => e.extension?.name)
      return adminEntryFilterList(menuEntries, {
        hasExperimentalAccess:
          rootGetters['app/context/hasExperimentalAccess'](),
        activeExtensionNames,
        toggles: rootGetters['app/features/toggles'],
        tenantConfig: rootGetters['app/context/tenantConfig'](),
        isTenantAdmin: rootGetters['app/context/isTenantAdmin'](),
        isCustomerAdmin: rootGetters['app/context/isCustomerAdmin'](),
      })
    },
    isAdminMode: (state, _getters, _rootState, rootGetters) => () => {
      return rootGetters['app/context/isAdmin']() && state.adminMode
    },
  },
  actions: {
    init({ commit }) {
      const recentlyAdded = localStorage.getItem('app/recentlyAdded')
      if (recentlyAdded) {
        commit('recentlyAdded', recentlyAdded === 'true')
      }
    },
  },
}

function isAdminEntryVisible(
  e,
  {
    hasExperimentalAccess,
    toggles,
    tenantConfig,
    isTenantAdmin,
    isCustomerAdmin,
  }
) {
  return (
    !e.user &&
    (!e.experimental || (e.experimental && hasExperimentalAccess)) &&
    (!e.shouldBeShown || e.shouldBeShown(toggles, tenantConfig)) &&
    (!e.tenantAdmin || (e.tenantAdmin && isTenantAdmin)) &&
    (!e.customerAdmin || (e.customerAdmin && isCustomerAdmin))
  )
}

function isAdminEntryItemVisible(i, hasExperimentalAccess, toggles) {
  return (
    (!i.experimental || (i.experimental && hasExperimentalAccess)) &&
    (!i.shouldBeShown || i.shouldBeShown(toggles))
  )
}

/**
 *
 * @param {Array} list all predefined menu entries
 * @param {Object} obj - an object containing access information
 * @param {Boolean} obj.hasExperimentalAccess has access to experimental features or not
 * @param {string[]} obj.activeExtensions list of active extension names
 * @param {string[]} obj.toggles list of feature active flags
 * @param {Object} obj.tenantConfig configuration of the current tenant
 * @param {Boolean} obj.isTenantAdmin is tenant admin
 * @param {Boolean} obj.isTenantAdmin is customer admin
 * @returns {Array} filtered admin menu entries
 */
function adminEntryFilterList(
  list,
  {
    hasExperimentalAccess,
    activeExtensions,
    toggles,
    tenantConfig,
    isTenantAdmin,
    isCustomerAdmin,
  }
) {
  return [...list]
    .filter(
      entry =>
        (!entry.extension || activeExtensions.includes(entry.extension)) &&
        isAdminEntryVisible(entry, {
          hasExperimentalAccess,
          toggles: toggles || [],
          tenantConfig,
          isTenantAdmin,
          isCustomerAdmin,
        })
    )
    .reduce((total, current) => {
      const { items, ...rest } = current

      const newItems = items.length
        ? items.filter(item =>
            isAdminEntryItemVisible(item, hasExperimentalAccess, toggles)
          )
        : []

      total.push({ ...rest, items: newItems })

      return total
    }, [])
}

function menu() {
  return [
    {
      id: 'home-dashboard',
      icon: 'mdi-home',
      title: 'nav.item.dashboard.title',
      route: { name: 'dashboard' },
      matcher: 'dashboard',
      items: [],
    },
    {
      id: 'recently-added',
      icon: 'mdi-file-clock-outline',
      title: 'nav.item.recentlyAdded.title',
      route: { name: 'recently-added' },
      matcher: 'recently-added',
      user: true,
      items: [],
    },
    {
      id: 'datacleanup',
      icon: 'mdi-delete-clock',
      title: 'nav.item.datacleanup.title',
      route: { name: 'datacleanup' },
      matcher: 'datacleanup',
      admin: true,
      items: [],
    },
    {
      id: 'processes',
      component: 'AProcessesNavItem',
      icon: 'mdi-folder-clock',
      title: 'nav.item.processes.title',
      route: { name: 'processes' },
      matcher: 'processes',
      items: [],
    },
    {
      id: 'modeling',
      icon: 'mdi-palette-advanced',
      title: 'nav.group.modeling.title',
      matcher: 'modeling/[a-z]+',
      admin: true,
      shouldBeShown: toggles =>
        !toggles.includes('dm-hide-admin-menu-entry-modeling'),
      items: [
        {
          id: 'modeling-traits',
          icon: 'mdi-ballot',
          title: 'nav.item.traits.title',
          route: { name: 'modeling-traits' },
        },
        {
          id: 'modeling-types',
          icon: 'mdi-file-table-box',
          title: 'nav.item.types.title',
          route: { name: 'modeling-types' },
        },
        {
          id: 'modeling-srvos',
          icon: 'mdi-arrow-decision-auto',
          title: 'nav.item.srvos.title',
          route: { name: 'modeling-srvos' },
        },
      ],
    },
    {
      id: 'appearance',
      icon: 'mdi-monitor-dashboard',
      title: 'nav.group.appearance.title',
      matcher: 'settings/(appearance)|(themes)|(actions)',
      admin: true,
      items: [
        {
          id: 'appearance-general',
          icon: 'mdi-file-presentation-box',
          title: 'nav.item.general.title',
          route: { name: 'settings-appearance' },
          exact: true,
        },
        {
          id: 'appearance-themes',
          icon: 'mdi-palette',
          title: 'nav.item.themes.title',
          route: { name: 'settings-themes' },
          exact: true,
        },
        {
          id: 'appearance-actions',
          icon: 'mdi-gesture-tap-button',
          title: 'nav.item.actions.title',
          route: { name: 'settings-actions' },
        },
      ],
    },
    {
      id: 'authentication',
      icon: 'mdi-shield-account',
      title: 'nav.group.auth.title',
      matcher: 'settings/(idps)|(users)|(serviceaccounts)|(roles)',
      admin: true,
      items: [
        {
          id: 'authentication-idps',
          icon: 'mdi-server-security',
          title: 'nav.item.idps.title',
          route: { name: 'settings-idps' },
        },
        {
          id: 'authentication-users',
          icon: 'mdi-account',
          title: 'nav.item.users.title',
          route: { name: 'settings-users' },
        },
        {
          id: 'authentication-serviceaccounts',
          icon: 'mdi-account-cog',
          title: 'nav.item.serviceaccounts.title',
          route: { name: 'settings-serviceaccounts' },
        },
        {
          id: 'authentication-roles',
          icon: 'mdi-account-group',
          title: 'nav.item.roles.title',
          route: { name: 'settings-roles' },
        },
      ],
    },
    {
      id: 'dataport',
      icon: 'mdi-file-export',
      title: 'nav.item.dataport.title',
      route: { name: 'dataport' },
      matcher: 'dataport',
      customerAdmin: true,
      shouldBeShown: (_, tenantCfg) => tenantCfg.enableDataExport === true,
      admin: true,
      items: [],
    },
    {
      id: 'billing',
      icon: 'mdi-chart-box-outline',
      title: 'nav.group.billing.title',
      route: { name: 'billing' },
      admin: true,
      tenantAdmin: true,
      bottom: true,
      items: [],
    },
    {
      id: 'audit-log',
      icon: 'mdi-text-box-search-outline',
      title: 'nav.group.auditLog.title',
      route: { name: 'audit-log' },
      admin: true,
      tenantAdmin: true,
      bottom: true,
      items: [],
    },
    {
      id: 'custom-services',
      icon: 'mdi-kubernetes',
      title: 'nav.group.customservices.title',
      route: { name: 'custom-services' },
      admin: true,
      experimental: true,
      bottom: true,
      items: [],
    },
    {
      id: 'extensions',
      icon: 'mdi-puzzle',
      title: 'nav.group.extensions.title',
      route: { name: 'settings-extensions' },
      admin: true,
      bottom: true,
      items: [],
      shouldBeShown: toggles =>
        !toggles.includes('dm-hide-admin-menu-entry-extension'),
    },
  ]
}
