export default (model = {}) => {
  return {
    ...{
      id: '',
      name: '',
      label: '',
      trigger: {
        name: '',
        properties: [],
      },
      templateDefinition: {
        tasks: [],
      },
      roles: {
        customeradmin: false,
        tenantadmin: false,
        tenantuser: false,
        partner: false,
      },
    },
    ...model,
  }
}
